<template>
  <div id="info_container">
    <div class="info_content_container__item info_container">
      <div class="info_container__item title_container">
        <div v-if="titleRight" class="title_container__item title_left">
          {{ titleRight }}
        </div>
        <div v-if="titleLeft" class="title_container__item title_right">
          {{ titleLeft }}
        </div>
      </div>
      <div v-for="(el, i) in data" :key="i" class="info_container__item line_container">
        <div class="line_container__item line_title_container">
          <div v-if="el.titleInfo" class="line_title_container__item container_title">
            <label>{{ el.titleInfo }}</label>
          </div>
          <div class="line_title_container__item line_title"
            :class="Array.isArray(el.title) ? 'multyline_container' : ''">
            <label v-for="(title_el, title_i) in (Array.isArray(el.title) ? el.title : [el.title])" :key="title_i"
              :class="Array.isArray(el.title) ? 'multyline_container__item' : ''">{{ title_el }}</label>
          </div>
        </div>
        <div class="line_container__item line_value multyline_container" :title="el.value"
          :style="el.valueColor ? `color: ${el.valueColor}` : ''">
          <label v-for="(value_el, value_i) in (Array.isArray(el.value) ? el.value : [el.value])" :key="value_i"
            class="multyline_container__item">{{ wrapIfNull(value_el) }}</label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InfoContainer",
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    titleRight: {
      type: String,
      default() {
        return null;
      },
    },
    titleLeft: {
      type: String,
      default() {
        return null;
      },
    },
  },
  methods: {
    wrapIfNull(value) {
      return value !== null && value !== undefined ? value : "Неизвестно";
    },
  },
};
</script>

<style lang="less">
.info_container {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  font-family: "Roboto";
  font-style: normal;
  width: 90%;
}

.title_container {
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  .title_left {
    width: 50%;
  }

  .title_right {
    width: 50%;
  }
}

.line_container {
  display: flex;
  border-bottom: 0.9px dashed #c9c9c9;
  padding-bottom: 5px;
  margin-top: 15px;
  align-items: flex-end;

  .line_title_container {
  width: 30%;
  display: flex;
  flex-direction: column;


  .container_title {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #a1a3ab;

  }
}

  .line_value {
    label {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 95%;

    }
    width: 100%;


  }

  .line_title {
    width: 100%;
    label {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 95%;

    }



  }
}



.multyline_container {
  display: flex;
  flex-direction: column;
}
</style>
